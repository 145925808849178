import config from 'config'
import Cookies from 'js-cookie';
import { Route } from 'vue-router'
import rootStore from '@vue-storefront/core/store'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isServer } from '@vue-storefront/core/helpers';
import axios from 'axios'

let nuxtUrls: string[] = []

// Fetch URLs from https://www.ribblecycles.co.uk/urls.json and cache them
async function fetchNuxtUrls() {
  try {
    const response = await axios.get('https://www.ribblecycles.co.uk/urls.json')
    const data = response.data
    nuxtUrls = (Object.values(data) as string[][]).reduce((acc, val) => acc.concat(val), [])
  } catch (error) {
    console.error('Error fetching Nuxt URLs:', error)
  }
}

fetchNuxtUrls()

export async function beforeEach (to: Route, from: Route, next) {
  const cartToken = Cookies.get('cart_id') as string | undefined;
  const userToken = Cookies.get('customer_token') as string | undefined;
  const externalCheckoutConfig = {...config.externalCheckout};
  const stores = externalCheckoutConfig.stores;
  const storeCode = currentStoreView().storeCode
  const multistoreEnabled: boolean = config.storeViews.multistore
  let currentCountryCode = '';
  let currentCurrencyCode = '';
  if (!isServer) {
    currentCountryCode = rootStore.getters['ayko_localisation/getCountry'] || currentStoreView().i18n.defaultCountry;
    currentCurrencyCode = rootStore.getters['ayko_localisation/getCurrency'] || currentStoreView().i18n.currencyCode;
  }

  //const isUKSite = /\.co\.uk$/.test(window.location.hostname); 
  if (nuxtUrls.includes(to.path)) {
      window.location.href = to.path; 
      return;
  }

  if (multistoreEnabled) {
    if (storeCode in stores && to.name === storeCode + '-checkout') {
      window.location.replace(stores[storeCode].cmsUrl + '/vue/cart/sync/token/' + userToken + '/cart/' + cartToken + '/currency/' + currentCurrencyCode + '/country/' + currentCountryCode)
    } else if (storeCode in stores && to.name === 'checkout' && stores[storeCode].cmsUrl !== undefined) {
      window.location.replace(stores[storeCode].cmsUrl + '/vue/cart/sync/token/' + userToken + '/cart/' + cartToken + '/currency/' + currentCurrencyCode + '/country/' + currentCountryCode)
    } else {
      next()
    }
  } else {
    if (to.name === 'checkout') {
      window.location.replace(stores[storeCode].cmsUrl + '/vue/cart/sync/token/' + userToken + '/cart/' + cartToken + '/currency/' + currentCurrencyCode + '/country/' + currentCountryCode)
    } else {
      next()
    }
  }
}