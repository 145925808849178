<template>
  <div
    class="font-featured"
    :class="showMobileMenu ? 'h-screen flex flex-col' : null"
  >
    <header
      class="bg-black py-3 lg:py-5 z-10 lg:z-20 relative w-full"
      :class="isHome ? 'lg:bg-opacity-80' : null"
      @mouseenter="closeMenu"
      @mouseleave="hideAccountDropdown"
    >
      <div
        class="rc-container mx-auto flex justify-between items-center max-w-screen-3xl"
      >
        <button
          class="flex flex-col h-12 w-12 justify-center items-center group rc-hidden-lg"
          @click="toggleMobileMenu"
        >
          <div
            class="h-1 w-6 my-0.5 rounded-full bg-white transition ease transform duration-900"
            :class="
              showMobileMenu
                ? 'rotate-45 translate-y-2 opacity-100'
                : 'opacity-100'
            "
          />
          <div
            class="h-1 w-6 my-0.5 rounded-full bg-white transition ease transform duration-900"
            :class="showMobileMenu ? 'opacity-0' : 'opacity-100'"
          />
          <div
            class="h-1 w-6 my-0.5 rounded-full bg-white transition ease transform duration-900"
            :class="
              showMobileMenu
                ? '-rotate-45 -translate-y-2 opacity-100'
                : 'opacity-100'
            "
          />
        </button>

        <button
          type="button"
          @click="navigatePage(localizedRoute('/'))"
          class="ml-10 lg:ml-3"
        >
          <RibbleLogo class="h-10 text-white" />
        </button>

        <ul class="text-white flex items-center text-base">
          <li
            @mouseenter="hideAccountDropdown"
            class="px-3 lg:px-7 md:flex md:items-center md:gap-2 lg:border-r"
          >
            <button
              @click="toggleSearch"
              type="button"
              class="flex flex-row items-center p-3 lg:p-0"
            >
              <span class="rc-hidden mr-5">{{ $t('Search') }}...</span>
              <MagnifyingGlass class="h-4" />
            </button>
          </li>
          <li
            class="rc-hidden px-3 lg:px-7 md:flex md:items-center md:gap-2 lg:border-r"
          >
            <AccountIcon />
          </li>
          <li
            @mouseenter="hideAccountDropdown"
            class="rc-hidden px-3 md:flex md:items-center md:gap-2 lg:border-r lg:px-7"
          >
            <router-link  v-if="isDE" :to="localizedRoute('/help-centre')">
              {{ $t('Help') }}
            </router-link>
            <a v-else href="https://helpcentre.ribblecycles.co.uk/support/home">
              {{ $t('Help') }}
            </a>
          </li>
          <li
            @mouseenter="hideAccountDropdown"
            class="px-3 flex items-center lg:border-r lg:px-7"
          >
            <client-only>
              <CartBtn />
            </client-only>
          </li>
          <li
            @mouseenter="hideAccountDropdown"
            class="rc-hidden px-3 md:block lg:px-7 lg:pr-3"
          >
            <Transition name="fade" mode="out-in">
              <client-only>
                <LanguageSwitcher
                  v-if="getCountry && multistoreEnabled"
                  :key="getCountry"
                />
              </client-only>
            </Transition>
          </li>
        </ul>
      </div>
    </header>
    <Transition name="slide">
      <MobileMenu v-if="showMobileMenu" />
    </Transition>
    <Transition name="slide">
      <SearchBar v-if="showSearch" />
    </Transition>
  </div>
</template>

<script>
import config from 'config';
import ClientOnly from 'vue-client-only';
import { mapGetters, mapActions } from 'vuex';
import CurrentPage from 'theme/mixins/currentPage';
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import CartBtn from 'theme/components/theme/bikebuilder/graphql-cart/CartBtn';
import AccountIcon from 'theme/components/theme/bikebuilder/AccountIcon';
import LanguageSwitcher from 'theme/components/core/LanguageSwitcher';
import LanguageIcon from '../ui-icons/LanguageIcon';
import MagnifyingGlass from '../ui-icons/MagnifyingGlass';
import ShoppingCart from '../ui-icons/ShoppingCart';
import MobileMenu from './MobileMenu';
import RibbleLogo from './RibbleLogo';
import SearchBar from './SearchBar';

export default {
  name: 'RibbleHeader',
  mixins: [CurrentPage, onEscapePress],
  components: {
    MobileMenu,
    CartBtn,
    LanguageSwitcher,
    MagnifyingGlass,
    ShoppingCart,
    AccountIcon,
    LanguageIcon,
    RibbleLogo,
    SearchBar,
    ClientOnly
  },
  computed: {
    ...mapGetters('craftCms', ['showSearch', 'showMobileMenu']),
    ...mapGetters('ayko_localisation', ['getCountry']),
    ...mapGetters('esw', ['getUserCountry']),
    isDE () {
      return this.getUserCountry === 'DE';
    },
    isHome() {
      return this.$route.name === 'home';
    },
    multistoreEnabled() {
      return config.storeViews.multistore;
    }
  },
  methods: {
    ...mapActions('graphqlAccount', [
      'hideAccountDropdown',
      'showAccountDropdown'
    ]),
    ...mapActions('craftCms', [
      'toggleSearch',
      'closeMenu',
      'closeSearch',
      'toggleMobileMenu',
      'closeMobileMenu'
    ]),
    onEscapePress() {
      this.closeSearch();
    },
    navigatePage(url) {
      if (this.$route.path !== url) {
          const homepageUrl = localizedRoute('/');
          window.location.href = homepageUrl;
      }
      this.closeMobileMenu();
    }
  }
};
</script>

<style scoped>
.rc-hidden {
  display: none;
}
.rc-container {
  max-width: 1820px;
}
@media (min-width: 1024px) {
  .rc-hidden {
    display: flex;
  }
}
.slide-move {
  transition: all 600ms ease-in-out 50ms;
}
.slide-enter-active {
  transition: all 300ms ease-out;
}

.slide-leave-active {
  transition: all 200ms ease-in;
}

.slide-enter {
  transform: translate(-100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
@media (min-width: 1024px) {
  .rc-hidden-lg {
    display: none;
  }
}
</style>
