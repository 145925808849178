// @ts-nocheck
import { defaultColorSku } from 'theme/store/configurator/color';
import { optionNameMap } from 'theme/components/theme/bikebuilder/configurator/utils/helpers';
import {
  allColourOptions,
  titleMap
} from 'theme/store/configurator/color.types';

class RequiredOptionsError extends Error {
  options_: any[];
  constructor(options: any[], ...params: string[]) {
    super(...params);
    this.options_ = options;
  }
}

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

function base64Encode(str) {
  return btoa(str);
}
function base64Decode(str) {
  return atob(str);
}
function formatOptionId(
  parentUid,
  valueIndex,
  attributeId,
  integratedValueIndex = null,
  integratedAttributeId = null
) {
  const decode = base64Decode(parentUid);
  let newUid = '';
  if (integratedValueIndex && integratedAttributeId) {
    newUid = `${decode}/${valueIndex}/${attributeId}/${integratedValueIndex}/${integratedAttributeId}`;
  } else {
    newUid = `${decode}/${valueIndex}/${attributeId}`;
  }
  return base64Encode(newUid);
}
function selectOptionValueIndexByLabel(cartOptions, label) {
  const options = [];
  cartOptions.forEach((option) => {
    if (option && option.label && option.label === label) {
      options.push(option);
    }
  });
  return options[0] ? options[0].value_index : null;
}
function selectChildOptionByLabel(cartOptions, label) {
  const options = [];
  cartOptions.forEach((option) => {
    if (option && option.label && option.label === label) {
      options.push(option);
    }
  });
  return options[0] ? options[0] : null;
}
function selectChildOptionByValueIndex(cartOptions, valueIndex) {
  const options = [];
  cartOptions.forEach((variant) => {
    if (variant && variant.attributes) {
      variant.attributes.forEach((attr) => {
        if (attr.value_index === valueIndex) {
          options.push(variant);
        }
      });
    }
  });
  return options[0] ? options[0] : null;
}
function getDefaultOrCheapestProduct(options) {
  // Search for a default product
  if (!options) return;
  const defaultProduct = options.find((option) => option.is_default);

  if (
    defaultProduct &&
    defaultProduct.product &&
    defaultProduct.product.stock_status === 'IN_STOCK'
  ) {
    // Return the default product if found and in stock
    return defaultProduct;
  } else {
    // Check if there's any product priced at 0
    const productWithZeroPrice = options.find((option) => option.price === 0);

    // If there is a product with a price of 0, return the first one found
    if (productWithZeroPrice) {
      return productWithZeroPrice;
    }

    // Otherwise, use the cheapest product if no default product is found
    const cheapestProduct = options.reduce((prev, current) =>
      prev.price < current.price ? prev : current
    );

    return cheapestProduct;
  }
}
export const formatPrice = (
  price: number,
  locale: string,
  currency: string,
  useSign = false
) => {
  if (!locale || !currency) return 0;
  const sign = price > 0 && useSign ? '+' : '';
  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0
  }).format(price);
  return `${sign}${formattedPrice}`;
};
/**
 * The `setDependent` helper method is designed to manage the dependencies between different configuration options
 * in the configurator. When a user selects an option that has associated dependencies (e.g, selecting a certain
 * type of handlebar requires a specific stem type), this method ensures that dependent options are updated.
 * Based on the dependent_on field within each option, which holds the linked dependent sku
 *
 * Parameters:
 * - `selectedOptions`: An array of the currently selected options within the product configurator
 *
 * Workflow:
 * 1. Iterates through the `selectedOptions` to find options that have dependencies. For instance, it might look for a
 *    'Stem' option that is dependent on the 'Handlebar' option selected by the user.
 * 2. For each option with dependencies, it identifies the dependent option(s) based on the currently selected option. This
 *    for instance involves checking the SKU of a selected handlebar and determining the appropriate stem option that matches
 *    this selection.
 * 3. Updates the relevant option(s) in `selectedOptions` to reflect the dependencies. This might mean setting the
 *    'Stem' option to a specific SKU and updating its title, description, or other properties to match the dependent
 *    selection.
 * 4. This method does not directly modify the Vuex state; instead, it prepares the updated options array, which should
 *    be subsequently used to update the state through a mutation.
 *
 */

const setDependent = (selectedOptions) => {
  // set corresponding stem to be the selected item
  const stemOption = selectedOptions.find((option) => option.name === 'Stem');
  const handleOption = selectedOptions.find(
    (option) => option.name === 'Handlebar'
  );

  if (stemOption && handleOption) {
    const selectedHandleBar = handleOption.options.find(
      (option) => option.product.sku === handleOption.sku
    );
    const dependentOption = stemOption.options.find(
      (o) => o.product.sku === selectedHandleBar.dependent_on
    );

    if (dependentOption) {
      Object.assign(stemOption, {
        ...stemOption,
        title: dependentOption.product.name,
        sku: dependentOption.product.sku,
        uid: dependentOption.uid,
        description: dependentOption.product.description
      });
    }
  }
};
function createOptionObject(item, product, valid = false, confirmed = false) {
  const productDescription =
    product.product.description && product.product.description.html;
  return {
    uid: product.uid,
    option_id: item.option_id,
    hexcode: product.hexcode,
    sku: product.product.sku || '',
    valid: valid ? valid : !item.required,
    included: item.included || false,
    opened: false,
    required: item.required,
    confirmed: confirmed,
    title: product.product.name || '',
    name: item.title,
    image: '',
    description: productDescription || '',
    options: item.options
  };
}

export const configuratorStore = {
  namespaced: true,
  state: {
    selectedOptions: [],
    handlebarOption: {},
    sizeOption: {},
    stemOption: {},
    frameColor: {},
    gradientColor: {},
    decalColor: {},
    gradientAngle: {},
    paintFinish: {},
    accentColor1: {}
  },
  mutations: {
    setOptions: (state, data) => {
      state.selectedOptions = data;
      setDependent(data); // TODO this does nothing?
    },
    setSelectedOption: (state, updatedState) => {
      const foundOption = state.selectedOptions.find(
        (option) => option.option_id === updatedState.option_id
      );
      if (foundOption) {
        Object.assign(foundOption, { ...foundOption, ...updatedState });
      }
      state.selectedOptions = [...state.selectedOptions];
    },
    setAdditionalOption: (state, item) => {
      state[item.optionName] = item.value;
    },
    clearSizeSelections: (state) => {
      state.handlebarOption = {};
      state.stemOption = {};
    },
    clearFrameSizeSelection: (state) => {
      state.sizeOption = {};
    },
    clearGradientColor: (state) => {
      state.gradientColor = {};
    },
    clearColourSelections: (state) => {
      state.frameColor = {};
      state.gradientColor = {};
      state.gradientAngle = {};
      state.decalColor = {};
      state.paintFinish = {};
      state.accentColor1 = {};
    },
    clearOptions: (state) => {
      state.selectedOptions = [];
    },
    SET_FRAME_COLOUR: (state, option) => {
      state.frameColor = option;
    }
  },
  actions: {
    clearFrameSizeSelection: async ({ commit }) => {
      commit('clearFrameSizeSelection');
    },
    /**
     * Teardown state that will persist in vuex stores across client side
     * rendered page views, going from one bike to another via client side routing
     */
    resetConfigurator: async ({ dispatch, rootGetters }) => {
      await dispatch('app/setInitialState', '', { root: true });
      await dispatch('configurator/clearOptions', '', { root: true });
      await dispatch(
        'configurator/setOptions',
        { options: rootGetters['bike/getBikeOptions'] },
        { root: true }
      );
      await dispatch(
        'app/setSteps',
        rootGetters['bike/getSectionItemsLength'],
        { root: true }
      );
    },
    /**
     * The `applyColourDefaults` action applies the colour defaults via {@link getDefaultOrCheapestProduct}
     * Additionally it filters out clashing colour choices across all of the primary level colour options
     * Decal, Frame etc, based on the current selection, this also set the initial paint finish default
     */
    applyColourDefaults: ({ commit, rootState, dispatch }) => {
      commit('clearColourSelections');
      rootState.customColour.bundleOptions = [];

      const colourOption = rootState.configurator.selectedOptions.find(
        (option) => option.option_id === 1234
      );
      if (colourOption) {
        const { sku } = colourOption;
        const selectedColour = rootState.color.color.find((c) => c.sku === sku);
        const items = selectedColour.items;
        const colours = [];

        for (const item in items) {
          const defaultProduct = getDefaultOrCheapestProduct(
            /**
             * Filters out already choosen products with clashing colours
             */
            items[item].options.filter((o) => !colours.includes(o.hexcode))
          );
          if (defaultProduct.hexcode) {
            colours.push(defaultProduct.hexcode);
          }
          switch (items[item].title) {
            case 'Frame Colour':
            case 'Decal Colour':
            case 'Paint Finish':
              const optionName = optionNameMap(items[item].title, 'name');
              const slug = optionNameMap(optionName, 'slug');
              dispatch(
                'configurator/setAdditionalOption',
                {
                  value: {
                    ...defaultProduct.product,
                    uid: defaultProduct.uid,
                    swatch: defaultProduct.hexcode
                  },
                  optionName,
                  slug,
                  name: defaultProduct.product.name,
                  bundleOptionId: slug,
                  swatch: defaultProduct.hexcode
                },
                { root: true }
              );
              break;
          }
        }
      }
    },
    clearColourSelections: ({ commit, rootState }) => {
      commit('clearColourSelections');
      rootState.customColour.bundleOptions = [];
    },
    applySizeMapping: ({ dispatch, rootState, getters }, item) => {
      const sizeOption = getters.getOptionByName('Size');
      // This is to determine if the call came from Size option selection or stem/handlebar
      const lookupItem =
        Object.keys(item).length > 0 ? item : rootState.configurator.sizeOption;

      const foundVariant = sizeOption.options
        .flatMap((option) => option.product.variants)
        .find((variant) =>
          variant.attributes.some((attribute) => {
            return attribute.value_index === lookupItem.value_index;
          })
        );

      if (foundVariant && 'size_mapping' in foundVariant) {
        Object.keys(foundVariant.size_mapping).map((key) => {
          const keyMap = {
            handlebar: 'Handlebar',
            stem: 'Stem',
            syspro_handlebar_size: 'handlebar',
            syspro_stem_size: 'stem'
          };
          const allowedMapping = ['syspro_handlebar_size', 'syspro_stem_size'];

          const option = getters.getOptionByName(keyMap[key]);
          let matchingVariants = [] as any;

          if (option) {
            try {
              for (const productOptions of option.options) {
                const parent = foundVariant.size_mapping[key].find(
                  (s) => s.parent === option.sku
                );

                for (const variant of productOptions.product.variants) {
                  if (variant.product.sku === parent.child) {
                    matchingVariants = variant.attributes.filter((a) =>
                      allowedMapping.includes(a.code)
                    );
                    break;
                  }
                }

                for (const matchingVariant of matchingVariants) {
                  if ('uid' in matchingVariant && 'label' in matchingVariant) {
                    const optionPrefix = keyMap[matchingVariant.code];
                    dispatch(
                      'configurator/setAdditionalOption',
                      {
                        value: {
                          sku: matchingVariant.uid,
                          name: matchingVariant.label,
                          label: `${optionPrefix} size`,
                          value_index: matchingVariant.value_index
                        },
                        optionName: `${optionPrefix}Option`
                      },
                      { root: true }
                    );
                  }
                }
              }
            } catch (e) {
              console.error(`size mapping not found for ${key}`);
            }
          }
        });
      }
    },
    clearOptions: async ({ commit }) => {
      commit('clearOptions');
      commit('clearColourSelections');
      commit('clearSizeSelections');
    },
    /**
     * The `setOptions` action is responsible for setting the initial selection of options within the product configurator.
     *
     * Workflow:
     * 1. Iterates through each option provided in the `data` payload, applying business logic to determine the default or required options.
     * 2. For each option, it checks conditions whether the option is required and it's default value via {@link getDefaultOrCheapestProduct}
     * 3. Optionally data is configured based on the ?colour query from the URL
     *
     * Example Usage:
     * This is used to set the initial option state data, and is designed to only be called once, or when the options are reset
     */

    setOptions: ({ commit, rootState, dispatch, rootGetters }, data) => {
      const selectedOptions = [];
      dispatch(
        'app/setImage',
        {
          image: rootState.bike.bike.products.items[0].image.url
        },
        { root: true }
      );
      for (const item of data.options) {
        // Stem should not select a default
        if (item.title === 'Stem') {
          const productObj = { product: { name: '', sku: '', uid: '' } };
          selectedOptions.push(
            createOptionObject(item, productObj, true, true)
          );
          continue;
        }
        if (!item.required) {
          const productObj = { product: { name: '', sku: '', uid: '' } };
          selectedOptions.push(
            createOptionObject(item, productObj, true, false)
          );
          continue;
        }

        const defaultProduct = getDefaultOrCheapestProduct(item.options);

        // set colour default
        if (item.title === 'Colour') {
          // set the colour from the URL param ?colour=black
          let colourWayProduct = null;
          if (data.colourQuery) {
            const product = rootGetters['graphqlProduct/getProduct'];
            const colourWays = product.colour_ways;
            colourWayProduct = colourWays.find(
              (c) =>
                c.colour_text.replace(/ /g, '-').toLowerCase() ===
                data.colourQuery
            );
          }

          const queriedProduct =
            data.colourQuery &&
            colourWayProduct &&
            item.options.find((i) => i.hexcode === colourWayProduct.hex);
          const colourDefaultProduct = queriedProduct
            ? queriedProduct
            : defaultProduct;

          dispatch('setFrameColour', {
            name: colourDefaultProduct.product.name,
            price: 0,
            ...colourDefaultProduct.product,
            uid: colourDefaultProduct.uid
          });
          dispatch(
            'app/setImage',
            {
              image: colourDefaultProduct.product.image.url
            },
            { root: true }
          );
          selectedOptions.push(
            createOptionObject(
              item,
              colourDefaultProduct,
              item.included,
              item.included
            )
          );
        } else {
          selectedOptions.push(createOptionObject(item, defaultProduct));
        }
      }

      commit('setOptions', selectedOptions);
    },
    /**
     * The `setAdditionalOption` action updates the state with additional, non-primary, product configuration options.
     * This is like the second layer of options, options within options! This is represented in the UI under additional options
     * or further selections within the primary selection see {@link setOptions}
     *
     * Workflow:
     * 1. Set the additional option by the optionName on the item
     * 2. Update the customColour store, this applies the correct imagery
     * 3. Additional specific logic for the gradient option, to apply a default angle, or clear the Angle
     */
    setAdditionalOption: ({ commit, dispatch, rootGetters, getters }, item) => {
      commit('setAdditionalOption', item);
      dispatch('customColour/setBundleOption', item, { root: true });
      //select gradient angle if user is selecting a gradientColor and none is selected
      if (item.optionName === 'gradientColor') {
        const angleOption = getters.getAdditionalOption('gradientAngle');
        if (
          !angleOption ||
          !angleOption.sku ||
          angleOption.sku === defaultColorSku
        ) {
          const optionId = getters.getOptionByName('Colour').option_id;
          const gradientOptions =
            rootGetters['color/gradientOptions'](optionId);
          const firstGradient = gradientOptions[0];
          dispatch(
            'configurator/setAdditionalOption',
            {
              value: {
                slug: 'gradient_angle',
                ...firstGradient.product,
                price: firstGradient.price,
                bundleOptionId: 'gradient_angle',
                gradientAngle: firstGradient.product.angle,
                angle: firstGradient.angle,
                uid: firstGradient.uid
              },
              product: firstGradient.product,
              angle: firstGradient.angle,
              bundleOptionId: 'gradient_angle',
              slug: 'gradient_angle',
              optionName: 'gradientAngle'
            },
            { root: true }
          );
        }
      }
      //handles when none selected
      if (
        item.optionName === 'gradientAngle' &&
        item.value.sku === defaultColorSku
      ) {
        commit('clearGradientColor');
        dispatch('customColour/clearGradientAngle', {}, { root: true });
      }
    },
    clearOption: ({ commit, getters }, selectedOption) => {
      const currentOption = getters.getSelectedOptions.find(
        (option) => option.option_id === selectedOption.option_id
      );
      const optionToUpdate = { ...currentOption, ...selectedOption };
      commit('setSelectedOption', optionToUpdate);
    },
    /**
     * The `selectOption` action updates the state with primary configuration options.
     * This is like the first layer of options, This is represented in the UI under as the main accordion sections
     * When an option within each section is selected by the user.
     *
     * Workflow:
     * 1. Validation specific to colour choices, to check the user has selected a colour when required
     * 2. Update the store with the selection, assuming passes 1.
     * 3. Apply size mapping for Stem and Handlebar
     * 4. Apply dependency mapping {@link setDependent}
     * 5. Update the customColour store
     */
    selectOption: async (
      { commit, dispatch, rootGetters, getters, rootState },
      selectedOption
    ) => {
      // Required colour option error handling
      if (selectedOption.option_id === 1234 && selectedOption.confirmed) {
        const requiredOptions = [];
        const colourOptions = getters.getColorItemsByFilters(
          allColourOptions,
          selectedOption.option_id
        );
        colourOptions.map((o) => {
          if (o.required) {
            const optionLookup = titleMap[o.title];
            const requiredOption = getters.getAdditionalOption(optionLookup);
            if (!requiredOption.sku) {
              requiredOptions.push(o.title);
            }
          }
        });
        if (requiredOptions.length > 0) {
          throw new RequiredOptionsError(
            requiredOptions,
            'Required options not selected'
          );
        }
      }

      commit('setSelectedOption', selectedOption);

      const currentOption = getters.getSelectedOptions.find(
        (option) => option.option_id === selectedOption.option_id
      );
      const optionToUpdate = { ...currentOption, ...selectedOption };

      if (['Stem', 'Handlebar'].includes(optionToUpdate.name)) {
        if ('sku' in selectedOption) {
          const fullSelectedOption = optionToUpdate.options.find(
            (p) => p.product.sku === optionToUpdate.sku
          );

          // run the size mapping
          dispatch('configurator/applySizeMapping', {}, { root: true });

          // if Handlebar and it’s an integrated bar/stem (no dependent_on)
          if (
            ['Handlebar'].includes(optionToUpdate.name) &&
            !currentOption.dependent_on
          ) {
            const stemOption = getters.getOptionByName('Stem');
            commit('setSelectedOption', {
              ...stemOption,
              title: '',
              sku: '',
              uid: '',
              image: {
                url: '',
                label: ''
              },
              description: ''
            });
            //stem size
            /*commit('setAdditionalOption', {
              value: {},
              optionName: 'stemOption'
            });*/
          }

          // dependent options
          if (fullSelectedOption.dependent_on) {
            switch (optionToUpdate.name) {
              case 'Handlebar':
                setDependent(rootState.configurator.selectedOptions);
            }
          }
        }
      }

      //update the customColour store
      dispatch('customColour/setBundleOption', selectedOption, { root: true });
    },
    setFrameColour: async ({ commit, rootState, dispatch }, option) => {
      await dispatch('clearFrameSizeSelection');
      //update the selectedOptions size option to use the corresponding frame
      const sizeOption = rootState.configurator.selectedOptions.find(
        (option) => option.name === 'Size'
      );
      commit('setSelectedOption', { ...sizeOption, sku: option.sku });
      commit('SET_FRAME_COLOUR', option);
    }
  },
  getters: {
    getSelectedOptions: (state) => {
      return state.selectedOptions;
    },
    getOptionByIndex: (state) => (index: number) => {
      return state.selectedOptions[index];
    },
    getOptionByName: (state) => (optionName: string) =>
      state.selectedOptions.find((option) => option.name === optionName),
    /**
     * Gets the configurable options of a primary option by a label e.g syspro_frame_size
     * this is done because the values which has the options does not have the full product
     * One we have the options we then have to return this along with full product.
     *
     * Workflow:
     * 1. Get the option by optionName
     * 2. Get the original option by the currently selected SKU
     * 3. If there is a filterBy the options are further filtered by the given optionName & label
     * 4. Loop through the options product variants, to return the available values, that match
     * The value_index
     */
    getConfigurableOptions:
      (state) => (optionName: string, label: string, filterBy: any) => {
        const optionByName = state.selectedOptions.find(
          (option) => option.name === optionName
        );
        let selectedOption = null;
        if (optionByName && optionByName.options) {
          selectedOption = optionByName.options.find(
            (option) => option.product.sku === optionByName.sku
          );
        }
        let filteredValues = [];

        if (selectedOption) {
          //filterBy for integrated stems
          if (filterBy) {
            const option = state[filterBy.optionName];
            filteredValues = selectedOption.product.variants
              .filter(
                (v) =>
                  v.attributes.some(
                    (a) => option && option.name && a.label === option.name
                  ) &&
                  v.attributes.some((a) => a.value_index === option.value_index)
              )
              .flatMap((v) =>
                v.attributes
                  .filter((a) => a.code === filterBy.label)
                  .map((o) => o.value_index)
              );
          }

          const configurableOptions =
            selectedOption.product.configurable_options.find(
              (option) => option.label === label
            );
          const filteredOptions = configurableOptions
            ? configurableOptions.values.filter((v) =>
                filterBy && filteredValues
                  ? filteredValues.includes(v.value_index)
                  : true
              )
            : [];
          const finalOptions = filteredOptions
            .map((o) => {
              const variant = selectedOption.product.variants.find((variant) =>
                variant.attributes.some((attribute) => {
                  return attribute.value_index === o.value_index;
                })
              );
              if (variant) {
                const product = variant.product;
                return { ...o, product };
              }
              return null;
            })
            .filter(Boolean);
          return finalOptions;
        } else {
          return [];
        }
      },
    getSizeAdditionalTabs: (state, getters) => {
      const DEFAULT_TAB = 'Handle Bar Options';
      let tabs = [
        {
          title: DEFAULT_TAB,
          optionName: 'handlebarOption',
          items: getters
            .getConfigurableOptions('Handlebar', 'syspro_handlebar_size')
            .map((item) => ({
              ...item,
              label: 'handlebar size'
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
        }
      ];
      //configurable integrated stem
      if (getters.hasConfigurableStem || !getters.getOptionByName('Stem')) {
        tabs.push({
          title: 'Stem Options',
          optionName: 'stemOption',
          items: getters
            .getConfigurableOptions('Handlebar', 'syspro_stem_size', {
              optionName: 'handlebarOption',
              label: 'syspro_stem_size'
            })
            .map((item) => ({
              ...item,
              label: 'stem size'
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
        });
      } else {
        tabs.push({
          title: 'Stem Options',
          optionName: 'stemOption',
          items: getters
            .getConfigurableOptions('Stem', 'syspro_stem_size')
            .map((item) => ({
              ...item,
              label: 'stem size'
            }))
            .sort((a, b) => a && a.name && a.name.localeCompare(b.name))
        });
      }
      return tabs;
    },
    getSelectedOption: (state) => (option_id: number) => {
      return state.selectedOptions.find(
        (option) => option.option_id === option_id
      );
    },
    getAdditionalOption: (state) => (name: string) => {
      return { ...state[name] };
    },
    getAdditionalOptions: (state, getters) => (name: string) => {
      const colorItems = [
        getters.getAdditionalOption('frameColor'),
        getters.getAdditionalOption('gradientColor'),
        getters.getAdditionalOption('decalColor'),
        getters.getAdditionalOption('gradientAngle')
      ];
      return colorItems;
    },
    getColourOptionPrice: (state, getters) => (item, name) => {
      let price = 0;
      const selectedOptionPrice = getters.getAdditionalOptionPrices(name);
      price = item.price - selectedOptionPrice.price;
      price = isNaN(price) ? 0 : price;

      return {
        price,
        formatted: {
          price: formatPrice(price, 'en-US', 'GBP')
        }
      };
    },
    getSelectedOptionSku: (state) => (option_id: number) => {
      const foundOption = state.selectedOptions.find(
        (option) => option.option_id === option_id
      );
      if (foundOption) {
        return foundOption.sku;
      }
    },
    getColorItemsByFilters:
      (state, getters, rootState) => (filters: [], optionId) => {
        /** Filter out the color options **/
        let tabs = [] as any;
        const selectedOption = getters.getSelectedOption(optionId);
        const selectedColorOption =
          rootState.color && rootState.color.color
            ? rootState.color.color.find(
                (item: any) => item.sku === selectedOption.sku
              )
            : null;
        if (selectedColorOption) {
          tabs = selectedColorOption.items.filter((item) => {
            // @ts-ignore
            return filters.includes(item.title);
          });
        }
        return tabs;
      },
    getSelections: (state) => {
      const {
        frameColor,
        decalColor,
        gradientColor,
        gradientAngle,
        handlebarOption,
        stemOption,
        sizeOption
      } = state;
      return {
        frameColor,
        decalColor,
        gradientColor,
        gradientAngle,
        stemOption,
        handlebarOption,
        sizeOption
      };
    },
    showRequired: (state) => (option_id: number) => {
      const option = state.selectedOptions.find(
        (option) => option.option_id === option_id
      );
      if (option) {
        return option.opened && !option.valid;
      }
    },
    /**
     * Determine if the Handlebar has a configurable stem by syspro_stem_size
     */
    hasConfigurableStem: (state, getters) => {
      let hasConfigurableStem = false;
      const handleBarOption = getters.getOptionByName('Handlebar');
      if (handleBarOption && handleBarOption.sku) {
        const selectedHandleBar = handleBarOption.options.find(
          (o) => o.product.sku === handleBarOption.sku
        );
        hasConfigurableStem =
          selectedHandleBar.product.configurable_options.some(
            (o) => o.label === 'syspro_stem_size'
          );
      }
      return hasConfigurableStem;
    },
    isReadyToOrder: (state) => {
      for (const option of state.selectedOptions) {
        if (!option.valid) {
          return false;
        }
      }
      if (state.sizeOption && state.sizeOption.product) {
        if (state.sizeOption.product.stock_status === 'OUT_OF_STOCK') {
          return false;
        }
      }
      return true;
    },
    unconfirmedOptions: (state) => {
      return state.selectedOptions.filter(
        (option) => option.opened && option.required && !option.valid
      );
    },
    getCartOptions: (state) => {
      // hack to remove options which are optional but not actually selected
      // the sku will be blank if not selected but the sku is the uid of the product
      const selectedOptions = state.selectedOptions.filter(
        (option) => option.sku !== ''
      );
      // remove item from selectedOptions that the option name is Colour and the sku includes BBCOLOUR
      const colourOptionToRemove = selectedOptions.find(
        (option) => option.name === 'Colour' && !option.sku.includes('BBCOLOUR')
      );
      let filteredColourOptions = [...selectedOptions];
      if (colourOptionToRemove) {
        filteredColourOptions = selectedOptions.filter(
          (option) => option.uid !== colourOptionToRemove.uid
        );
      }
      const bikeOptions = [...filteredColourOptions];
      if (state.handlebarOption && !isEmptyObject(state.handlebarOption)) {
        bikeOptions.push(state.handlebarOption);
      }
      if (state.stemOption && !isEmptyObject(state.stemOption)) {
        bikeOptions.push(state.stemOption);
      }
      if (state.sizeOption && !isEmptyObject(state.sizeOption)) {
        bikeOptions.push(state.sizeOption);
      }
      if (state.frameColor && !isEmptyObject(state.frameColor)) {
        bikeOptions.push(state.frameColor);
      }
      if (state.decalColor && !isEmptyObject(state.decalColor)) {
        bikeOptions.push(state.decalColor);
      }
      if (state.accentColor1 && !isEmptyObject(state.accentColor1)) {
        bikeOptions.push(state.accentColor1);
      }
      if (state.gradientColor && !isEmptyObject(state.gradientColor)) {
        bikeOptions.push(state.gradientColor);
      }
      if (state.gradientAngle && !isEmptyObject(state.gradientAngle)) {
        bikeOptions.push(state.gradientAngle);
      }
      if (state.paintFinish && !isEmptyObject(state.paintFinish)) {
        bikeOptions.push(state.paintFinish);
      }
      return bikeOptions;
    },
    getOptionsWithoutFrameHandlebarStem: (state) => {
      const selectedOptions = state.selectedOptions.filter(
        (option) => option.sku !== ''
      );
      // remove Colour option - separate logic for this
      const filteredOptions = selectedOptions.filter(
        (option) => option.name !== 'Colour'
      );
      const options = [];
      filteredOptions.forEach((option) => {
        if (
          option.name !== 'Size' &&
          option.name !== 'Handlebar' &&
          option.name !== 'Stem'
        ) {
          options.push(option);
        }
      });
      return options;
    },
    getCustomColourOptionsForCart: (state, getters) => {
      const options = [];
      if (getters.getSelectedFrameColor) {
        options.push(getters.getSelectedFrameColor);
      }
      if (getters.getSelectedGradientColor) {
        options.push(getters.getSelectedGradientColor);
      }
      if (getters.getSelectedDecalColor) {
        options.push(getters.getSelectedDecalColor);
      }
      if (getters.getSelectedAccent1Color) {
        options.push(getters.getSelectedAccent1Color);
      }
      if (getters.getSelectedGradientAngle) {
        options.push(getters.getSelectedGradientAngle);
      }
      if (getters.getSelectedPaintFinish) {
        options.push(getters.getSelectedPaintFinish);
      }
      return options;
    },
    getPreparedOptionsForCart: (state, getters, rootState, rootGetters) => {
      // hack to remove options which are optional but not actually selected
      // the sku will be blank if not selected but the sku is the uid of the product
      const selectedOptions = state.selectedOptions.filter(
        (option) => option.sku !== ''
      );
      const isCustomColour = rootGetters['color/getIsCustomColor'];
      const options = selectedOptions.map((option) => {
        return {
          name: option.name,
          uid:
            option.name === 'Size' && !isCustomColour
              ? getters.getSelectedFrameColor.uid
              : option.uid
        };
      });
      // Remove Colour option - separate logic for this
      return options.filter((option) => option.name !== 'Colour');
    },
    getOptionIdsForCart(state, getters) {
      if (!getters.getSelectedFrame) return [];
      const options = [];
      getters.getPreparedOptionsForCart.forEach((option) => {
        if (
          option.name === 'Handlebar' &&
          getters.getSelectedHandlebar &&
          !getters.isIntegratedHandlebar
        ) {
          const newOption = formatOptionId(
            option.uid,
            getters.getSelectedHandlebar.value_index,
            getters.getSelectedHandlebar.attribute_id
          );
          options.push(newOption);
        }
        if (
          option.name === 'Handlebar' &&
          getters.getSelectedHandlebar &&
          getters.isIntegratedHandlebar
        ) {
          const newOption = formatOptionId(
            option.uid,
            getters.getSelectedHandlebar.value_index,
            getters.getSelectedHandlebar.attribute_id,
            getters.getSelectedStem.value_index,
            getters.getSelectedStem.attribute_id
          );
          options.push(newOption);
        }
        if (
          option.name === 'Stem' &&
          getters.getSelectedStem &&
          !getters.isIntegratedHandlebar
        ) {
          const newOption = formatOptionId(
            option.uid,
            getters.getSelectedStem.value_index,
            getters.getSelectedStem.attribute_id
          );
          options.push(newOption);
        }
        if (option.name === 'Size' && getters.getSelectedFrame) {
          const newOption = formatOptionId(
            option.uid,
            getters.getSelectedFrame.value_index,
            getters.getSelectedFrame.attribute_id
          );
          options.push(newOption);
        }
      });
      getters.getOptionsWithoutFrameHandlebarStem.forEach((option) => {
        options.push(option.uid);
      });
      return options.filter((option) => option !== null);
    },
    getAdditionalOptionPrices: (state) => (optionName: string) => {
      const selectedOption = state[optionName];
      let price = 0;
      if (selectedOption && selectedOption.price) {
        price = selectedOption.price;
      }
      return { price };
    },
    getOptionPrices: (state, getters) => (option_id: number) => {
      const option = state.selectedOptions.find(
        (option) => option.option_id === option_id
      );

      let price = 0;
      let special_price = 0;
      let save = 0;

      const calculateSave = (price, special_price) =>
        special_price > 0 ? Math.abs(price - special_price) : 0;

      if (option) {
        // assign price if the option name is 'Colour'
        if (option.name === 'Colour') {
          price = option.price || 0;
        } else if (option.options) {
          // Find the selected option based on the SKU
          const selectedOption = option.options.find(
            (o) => o.product.sku === option.sku
          );

          if (selectedOption) {
            price = selectedOption.price;
            special_price = selectedOption.special_price;
            save = calculateSave(price, special_price);
          }
        }
      }

      return { price, special_price, save };
    },
    getPrice(state, getters, rootState, rootGetters) {
      const totalPrices = state.selectedOptions.map((option) =>
        getters.getOptionPrices(option.option_id)
      );

      //Add in the selected colour optionaliaties
      const colorItems = getters.getAdditionalOptions();
      for (const item in colorItems) {
        if (colorItems[item] && colorItems[item].price) {
          totalPrices.push({
            price: colorItems[item].price,
            special_price: 0
          });
        }
      }

      const sum = totalPrices.reduce(
        (acc, curr) => {
          return {
            price: acc.price + curr.price,
            now:
              acc.now +
              (curr.special_price > 0 ? curr.special_price : curr.price),
            special_price: acc.special_price + curr.special_price
          };
        },
        { price: 0, special_price: 0, now: 0 }
      );

      const locale = rootGetters['graphqlCart/getCartLocale'];
      const currency = rootGetters['graphqlCart/getCartCurrency'];

      const formattedSum = {
        price: formatPrice(
          sum.price - sum.special_price,
          locale,
          currency,
          false
        ),
        now: formatPrice(sum.now, locale, currency, false),
        special_price: formatPrice(sum.special_price, locale, currency, false),
        save: formatPrice(sum.price - sum.now, locale, currency, false),
        was: formatPrice(sum.price, locale, currency, false)
      };

      return {
        ...sum,
        was: sum.price + sum.save,
        formatted: { ...formattedSum }
      };
    },
    getSelectedFrame: (state, getters) => {
      const frame = selectChildOptionByLabel(
        getters.getCartOptions,
        'frame size'
      );
      if (frame) {
        frame.attribute_id = 839;
      }
      return frame;
    },
    getSelectedFrameId(state, getters) {
      return selectOptionValueIndexByLabel(
        getters.getCartOptions,
        'frame size'
      );
    },
    getSelectedChildFrame(state, getters, rootState, rootGetters) {
      if (!getters.getSelectedFrameId) return null;
      const variants = rootGetters['bike/getBikeVariantOptions'];
      return selectChildOptionByValueIndex(
        variants,
        getters.getSelectedFrameId
      );
    },
    getSelectedParentHandlebar(state, getters) {
      return getters.getSelectedOptions.find(
        (option) => option.name === 'Handlebar'
      );
    },
    isIntegratedHandlebar: (state, getters) => {
      const handlebar = getters.getSelectedParentHandlebar;
      if (!handlebar) return false;
      const parentSku = handlebar.sku;
      const selectedHandlebar = handlebar.options.find(
        (option) => option.product.sku === parentSku
      );
      return selectedHandlebar.product.configurable_options.length > 1;
    },
    getSelectedHandlebar(state, getters) {
      const handlebar = selectChildOptionByLabel(
        getters.getCartOptions,
        'handlebar size'
      );
      if (handlebar) {
        handlebar.attribute_id = 840;
        handlebar.configurable_options = [];
      }
      return handlebar;
    },
    getSelectedHandlebarId(state, getters) {
      return selectOptionValueIndexByLabel(
        getters.getCartOptions,
        'handlebar size'
      );
    },
    getSelectedChildHandlebar(state, getters, rootState, rootGetters) {
      if (!getters.getSelectedHandlebarId) return null;
      const variants = rootGetters['bike/getBikeVariantOptions'];
      return selectChildOptionByValueIndex(
        variants,
        getters.getSelectedHandlebarId
      );
    },
    getSelectedStem(state, getters) {
      const stem = selectChildOptionByLabel(
        getters.getCartOptions,
        'stem size'
      );
      if (stem) {
        stem.attribute_id = 841;
      }
      return stem;
    },
    getSelectedStemId(state, getters) {
      return selectOptionValueIndexByLabel(getters.getCartOptions, 'stem size');
    },
    getSelectedChildStem(state, getters, rootState, rootGetters) {
      if (!getters.getSelectedStemId) return null;
      const variants = rootGetters['bike/getBikeVariantOptions'];
      return selectChildOptionByValueIndex(variants, getters.getSelectedStemId);
    },
    getCustomColourParentOption: (state, getters) => {
      const option = getters.getCartOptions.find(
        (option) => option.name === 'Colour' && option.sku.includes('BBCOLOUR')
      );
      return option ? option : null;
    },
    getSelectedFrameSize: (state, getters) => {
      const frameSize = getters.getCartOptions.find(
        (option) => option.label === 'frame size'
      );
      return frameSize ? frameSize : null;
    },
    getSelectedFrameColor: (state) =>
      state.frameColor && !isEmptyObject(state.frameColor)
        ? state.frameColor
        : null,
    getSelectedGradientColor: (state) =>
      state.gradientColor && !isEmptyObject(state.gradientColor)
        ? state.gradientColor
        : null,
    getSelectedDecalColor: (state) =>
      state.decalColor && !isEmptyObject(state.decalColor)
        ? state.decalColor
        : null,
    getSelectedAccent1Color: (state) =>
      state.accentColor1 && !isEmptyObject(state.accentColor1)
        ? state.accentColor1
        : null,
    getSelectedGradientAngle: (state) =>
      state.gradientAngle && !isEmptyObject(state.gradientAngle)
        ? state.gradientAngle
        : null,
    getSelectedPaintFinish: (state) =>
      state.paintFinish && !isEmptyObject(state.paintFinish)
        ? state.paintFinish
        : null,
    getCustomColourOptionIdsForCart: (state, getters) => {
      return getters.getCustomColourOptionsForCart
        .map((option) => (option ? option.uid : null))
        .filter((uid) => uid !== null && uid !== undefined);
    },
    hasCustomColour: (state, getters) => {
      return !!getters.getCustomColourParentOption;
    },
    getBikeCartPayload: (state, getters, rootState, rootGetters) => {
      return {
        quantity: 1,
        sku: rootGetters['bike/getBikeSku'],
        selected_options: getters.getOptionIdsForCart
      };
    },
    getCustomColourPayload: (state, getters, rootState, rootGetters) => {
      if (!getters.getCustomColourParentOption) return null;
      return {
        quantity: 1,
        sku: getters.getCustomColourParentOption.sku,
        selected_options: getters.getCustomColourOptionIdsForCart
      };
    }
  }
};
